//
// Image loader to return Cornerstone image objects from Saince PACS web services.
//
// FN: create image object from response for Cornerstone viewer.
//
import pako from 'pako'
import Vue from 'vue'
import dicomweb from './dicomweb'
import webServices from './webServices'

function createImage(imageId, dicomMetadata, response) {
    //
    // The image object describes the image that is to be rendered.
    //
    // Ref: https://github.com/cornerstonejs/cornerstone/wiki/Image
    //
    const pixelRep = dicomweb.getIntegerValue(dicomMetadata, "00280103", 0)
    const bitsAlloc = dicomweb.getIntegerValue(dicomMetadata, "00280100", 8)
    const rows = dicomweb.getIntegerValue(dicomMetadata, "00280010")
    const cols = dicomweb.getIntegerValue(dicomMetadata, "00280011")
    const m = dicomweb.getDecimalValue(dicomMetadata, "00281053", 1)
    const b = dicomweb.getDecimalValue(dicomMetadata, "00281052", 0)
    var ww = dicomweb.getDecimalValue(dicomMetadata, "00281051", -1)
    var wc = dicomweb.getDecimalValue(dicomMetadata, "00281050", 0)

    // Reponse was compressed with zlib, decompress...
    //
    let responseInflated = pako.inflate(response)
    var pixelData = null
    if (bitsAlloc <= 8) {
        if (pixelRep == 1) {
            pixelData = new Int8Array(responseInflated.buffer)
        }
        else {
            pixelData = new Uint8Array(responseInflated.buffer)
        }
    }
    else if (bitsAlloc <= 16) {
        if (pixelRep == 1) {
            pixelData = new Int16Array(responseInflated.buffer)
        }
        else {
            pixelData = new Uint16Array(responseInflated.buffer)
        }
    }
    else {
        throw Error("")
    }

    var invert = false
    const photometricInterp = dicomweb.getValue(dicomMetadata, "00280004")
    if (photometricInterp == "MONOCHROME1") {
        invert = !invert
    }
    //const presentationLutShape = dicomweb.getValue(dicomMetadata, "20500020")
    //if (presentationLutShape == "INVERSE") {
    //    invert = !invert
    //}  
    var minPixelValue = Math.pow(2, 16)
    var maxPixelValue = -Math.pow(2, 16)
    for (var p = 0; p < pixelData.length; p++) {
        if (pixelData[p] > maxPixelValue) {
            maxPixelValue = pixelData[p]
        }
        if (pixelData[p] < minPixelValue) {
            minPixelValue = pixelData[p]
        }
    }
    if (ww < 0) {
        ww = maxPixelValue - minPixelValue
        wc = (maxPixelValue + minPixelValue) / 2
    }

    var rowSpacing = dicomweb.getDecimalValue(dicomMetadata, "00280030", -1, 0)	// Pixel Spacing
    rowSpacing = (rowSpacing > 0) ? rowSpacing : undefined
    var colSpacing = dicomweb.getDecimalValue(dicomMetadata, "00280030", -1, 1)	// Pixel Spacing
    colSpacing = (colSpacing > 0) ? colSpacing : undefined
    //var aspectRatio = dicomweb.getDecimalValue(dicomMetadata, "00280034")
    
    let image = {
        // Parameters required by cornerstone
        //
        imageId: imageId, // the imageId associated with this image object
        minPixelValue: minPixelValue, // the minimum stored pixel value in the image
        maxPixelValue: maxPixelValue, // the maximum stored pixel value in the image
        slope: m, // the rescale slope to convert stored pixel values to modality pixel values or 1 if not specified
        intercept: b, // the rescale intercept used to convert stored pixel values to modality values or 0 if not specified
        windowCenter: wc, // the default windowCenter to apply to the image
        windowWidth: ww, // the default windowWidth to apply to the image
        getPixelData: function() { return this.pixelData; }, // a function that returns the underlying pixel data. An array of integers for grayscale and an array of RGBA for color
        //getImageData: () => { return; }, // a function that returns a canvas imageData object for the image. This is only needed for color images
        //getCanvas: () => { return; }, // a function that returns a canvas element with the image loaded into it. This is only needed for color images.
        //getImage: () => { return; }, // a function that returns a JavaScript Image object with the image data. This is optional and typically used for images encoded in standard web JPEG and PNG formats
        rows: rows, // number of rows in the image. This is the same value as height but replicated for convenience
        columns: cols, // number of columns in the image. This is the same as width but replicated for convenience
        height: rows, // the height of the image. This is the same value as rows but replicated for convenience
        width: cols, // the width of the image. This is the same value as columns but replicated for convenience
        color: false, // true if pixel data is RGB, false if grayscale
        columnPixelSpacing: colSpacing, // horizontal distance between the middle of each pixel (or width of each pixel) in mm or undefined if not known
        rowPixelSpacing: rowSpacing, // vertical distance between the middle of each pixel (or height of each pixel) in mm or undefined if not known
        invert: invert, // true if the the image should initially be displayed be inverted, false if not. This is here mainly to support DICOM images with a photometric interpretation of MONOCHROME1
        sizeInBytes: pixelData.BYTES_PER_ELEMENT * rows * cols, // the number of bytes used to store the pixels for this image.
        
        // Additional parameters added for storing pixel data, other information
        //
        pixelData: pixelData,
        sizeInBytesCompressed: response.byteLength
    }
    return image
}

function loadImage(imageId, metadata, entry, seriesUid, instanceUid, frame, quality) {
    return new Promise((resolve, reject) => {
        webServices.readPixelData(metadata, entry, seriesUid, instanceUid, frame, quality)
        .then(response => {   
            try {
                let image = createImage(imageId.replace('custom:', ''), metadata, response)
                resolve(image)
            }
            catch(ciErr) {
                Vue.$log.error("loadImage err:"+ciErr.message)
                reject(ciErr)
            }
        })
        .catch(err => {
            Vue.$log.error("loadImage err:"+err.message)
            reject(err)
        })   
    })
}
export default {
    createImage,
    loadImage
}